import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <img src={require("./assets/logo.png")} style={{ maxWidth: 600 }} />
    </div>
  );
}

export default App;
